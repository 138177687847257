import { Injectable } from '@angular/core';
import { CollectionConfig, CollectionService } from 'akita-ng-fire';
import { ViewState, ViewStore } from './view.store';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'views', idKey: 'id' })
export class ViewService extends CollectionService<ViewState> {
  constructor(store: ViewStore) {
    super(store);
  }
}
