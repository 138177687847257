import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { PlanState, PlanStore } from './plan.store';

@Injectable({ providedIn: 'root' })
@QueryConfig({
  sortBy: 'name',
  sortByOrder: Order.ASC,
})
export class PlanQuery extends QueryEntity<PlanState> {
  constructor(protected store: PlanStore) {
    super(store);
  }
}
