import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActivePlanGuard, PlanListGuard } from './plan.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./plan-list').then((m) => m.PlanListModule),
    canActivate: [PlanListGuard],
    canDeactivate: [PlanListGuard],
  },
  {
    path: ':planId',
    loadChildren: () => import('./plan-viewer').then((m) => m.PlanViewerModule),
    canActivate: [ActivePlanGuard],
    canDeactivate: [ActivePlanGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class PlanModule {}
