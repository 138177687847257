import { Injectable } from '@angular/core';
import { CollectionGuard, CollectionGuardConfig } from 'akita-ng-fire';
import { PlanService, PlanState } from '@world/plan/state';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
@CollectionGuardConfig({ awaitSync: true })
export class PlansGuard extends CollectionGuard<PlanState> {
  constructor(protected service: PlanService) {
    super(service);
  }

  sync() {
    return this.service.sync();
  }
}

@Injectable({ providedIn: 'root' })
export class PlanListGuard extends CollectionGuard<PlanState> {
  constructor(protected service: PlanService) {
    super(service);
  }
}

@Injectable({ providedIn: 'root' })
@CollectionGuardConfig({ awaitSync: true })
export class ActivePlanGuard extends CollectionGuard<PlanState> {
  constructor(service: PlanService) {
    super(service);
  }

  sync(next: ActivatedRouteSnapshot) {
    return this.service.syncActive({ id: next.params.planId });
  }
}
