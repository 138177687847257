import { Injectable } from '@angular/core';
import { CollectionConfig, CollectionService } from 'akita-ng-fire';
import { PlanState, PlanStore } from './plan.store';
import { map, switchMap, tap } from 'rxjs/operators';
import { ViewQuery } from '@world/view/state';
import { Plan, Version } from '@atrius/model/mission/dronelink';
import { forkJoin, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'dronelink/exp/plans', idKey: 'id' })
export class PlanService extends CollectionService<PlanState> {
  constructor(store: PlanStore, private viewQuery: ViewQuery) {
    super(store);
  }

  sync() {
    return this.viewQuery.selectActive().pipe(
      tap(() => this.store.reset()),
      switchMap(({ planTags }) => {
        return this.syncCollection((ref) => ref.where('tags', 'array-contains-any', planTags));
      })
    );
  }

  getLatestVersion(id: string): Observable<Version> {
    return this.db
      .collection(this.path + `/${id}/versions`, (ref) => ref.orderBy('created', 'desc').limit(1))
      .get()
      .pipe(map((snapshot) => snapshot.docs[0].data() as Version));
  }

  getLatestVersions(plans: Plan[]): Observable<Version[]> {
    return forkJoin(plans.map((plan) => this.getLatestVersion(plan.id)));
  }
}
