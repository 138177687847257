import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { ViewState, ViewStore } from './view.store';

@Injectable({ providedIn: 'root' })
@QueryConfig({
  sortBy: 'name',
  sortByOrder: Order.ASC,
})
export class ViewQuery extends QueryEntity<ViewState> {
  constructor(protected store: ViewStore) {
    super(store);
  }
}
