import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig, EntityState, ActiveState } from '@datorama/akita';
import { Plan } from '@atrius/model/mission/dronelink';

export interface PlanState extends EntityState<Plan, string>, ActiveState<string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'plans', resettable: true })
export class PlanStore extends EntityStore<PlanState> {
  constructor() {
    super();
  }
}
