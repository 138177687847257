import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig, EntityState, ActiveState } from '@datorama/akita';
import { View } from '@atrius/model/view';

export interface ViewState extends EntityState<View, string>, ActiveState<string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'views' })
export class ViewStore extends EntityStore<ViewState> {
  constructor() {
    super();
  }
}
